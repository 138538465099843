@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');

.labelum {
    font-size: 20px;
    display: flex;
    justify-content: center;
    color: #00ADD3;
}

.labelum h1 {
    font-weight: bold;
}

.labelsecun {
    font-size: 1.5rem;
    font-weight: 700;
    color: #00ADD3;
    font-weight: 300;
    display: flex;
    justify-content: center;
    padding-bottom: 120px;
    padding-top: 50px;

    h2 {
        font-weight: 300;
    }
}

.container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: 'Work Sans', sans-serif; 
}

.esquerda {
    width: 60%;
    padding-left: 20px; /* Ajuste conforme necessário */
    padding-right: 20px; /* Ajuste conforme necessário */
    display: flex;
    flex-direction: column;

    .h1prin {
        padding-top: 60px;
        padding-bottom: 70px;
        padding-left: 200px;
    }

    .imageContainer {
        display: flex;
        justify-content: center; /* Centraliza a imagem do iPhone horizontalmente */
        padding-bottom: 40px; /* Adiciona espaço abaixo da imagem */
    }

    .h1secun {
        padding-top: 70px;
        padding-bottom: 40px;
        padding-left: 200px;
        padding-right: 20px;
    }

    button {
        background-color: #00ADD3;
        color: #001339;
        font-weight: 900;
        padding: 1.4rem 1.6rem; /* Reduz o tamanho do botão */
        border: none;
        border-radius: 1.8rem;
        cursor: pointer;
        font-weight: 600;
        align-self: flex-start; /* Alinha o botão à esquerda */
        margin-left: 200px; /* Ajuste conforme necessário */
        margin-top: 20px;
        font-size: x-large;
    }
}


.direita {
    width: 40%;
    display: flex;
    padding-left: 100px;
    padding-right: 100px;
    flex-direction: column;
    padding-top: 70px;

    .Imgdois {
        padding-bottom: 200px;
    }

    .Imgtres {
        padding-bottom: 100px;
    }
}

.highlight {
    color: #00ADD3;
}

@media (max-width: 988px) {
    .direita {
        display: none;
    }
    .esquerda {
        width: 100%;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
    }
    .esquerda .h1prin {
        padding-left: 0;
    }
    .esquerda .h1secun {
        padding-left: 0;
    }
    .esquerda button {
        margin-left: 0;
        margin-right: 0;
        align-self: center;
    }
    .labelum {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 50px;
        text-align: center;
    }
    button {
        margin: 0;
        align-self: center;
        margin-bottom: 50px;
    }
}
