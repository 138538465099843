.container {
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    width: 100%;
}

.labelprin {
    font-size: 2.18rem;
    font-weight: 700;
    color: #00ADD3;
    font-weight: 300;
    display: flex;
    justify-content: center;
    padding-top: 20px;

    h2 {
        font-weight: 300;
    }

}
.title {
    font-size: 1rem;
    
    h1 {
        font-weight: 400;
        margin: 0.8rem 0;
    }
}

.text {
    display: flex;
    flex-direction: column;
    width: 90%; /* Ajuste a largura do contêiner conforme necessário */
    max-width: 50rem; /* Defina uma largura máxima */
    font-size: 1.37rem;
}


.highlight {
    color: #00ADD3;
}

.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoesg {
    width: 100%;
    max-width: 1208px;
    height: 100%;
    max-height: 183px;
}

.banneresg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

@media (max-width: 1368px) {
    .banneresg {
        width: 900px; /* Ajuste a largura conforme necessário */
        max-width: 100%; /* Use largura máxima em telas menores */
        height: auto; /* Ajuste a altura automaticamente */
        padding-top: 200px;
    }

    .text {
        padding-right: 0px;
        text-align: center;
    }

    .title {
        padding-left: 0px;
        padding-top: 140px;
        text-align: center;
    }

    .content {
        flex-direction: column;
        gap: 1.5em;
    }

    .text1, .text2 {
        text-align: center; /* Centralize o texto */
    }
}

/* Para dispositivos com largura de tela até 740px */
@media (max-width: 740px) {
    .container,
    .content {
        /* Estilos para telas pequenas */
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .title {
        padding-top: 250px;
    }

    .banneresg {
        padding-top: 250px;
        padding-bottom: 50px;
    }

}

