.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;

    h1 {
        font-size: 1.8rem;
        font-weight: 300;
        color: #00ADD3;
    }

    h2 {
        font-size: 1.8rem;
    }

    p {
        font-size: 20px;
    }
}

.content {
    display: flex;
    flex-direction: row;
    gap: 30rem;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;

    h2 {
        margin-bottom: 1rem;
    }
}

.call {
    max-width: 40rem;
}

.contact {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #00ADD3;
        color: #001339;
        font-size: x-large;
        font-weight: 900;
        cursor: pointer;
        border: none;
        border-radius: 1.8rem;

        img {
            margin-left: 1rem;
        }

        p {
            margin: 1rem 1rem;
            color: white;
        }
    }
}

@media (max-width: 1608px) {
    .content {
        gap: 10rem;
    }
}

@media (max-width: 1200px) {
    .content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 3rem;
        padding-left: 20px;
        padding-right: 20px;
    }
}