.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 65px;
    background-color: #242628;
    color: #fff;
}

.tudao{
    img{
        cursor: pointer;
    }
}

.eletrix,
.container2,
.app {
    flex: 0 1 calc(33.33% - 20px); 
    margin-bottom: 20px;
}

.botaoplay, .botaoapp {
    background: none;
    border: none;
    cursor: pointer;
}

.social {
    button {
        background: none;
        border: none;
        cursor: pointer;
    }   
}

.eletrix .social {
    display: flex;
    align-items: center;
    gap: 10px; 
    margin-bottom: 10px;
}

.contato .WhatsApp,
.contato .Email {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.contato .WhatsApp img,
.contato .Email img {
    width: 30px;
    height: 30px;
}

.contato .WhatsApp div,
.contato .Email div {
    display: flex;
    flex-direction: column;
}

.eletrix .social p {
    margin-right: 10px;
    text-align: center;
    padding-bottom: 40px;
}

.eletrix {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    height: 100%; 
}

.eletrix p {
    font-size: 1.5em;
    padding-top: 40px;
}

.eletrixLogo {
    width: 14.06em;
    height: auto;
}

.whatslogo {
    width: 30px;
    height: 30px;
}

.emaillogo {
    width: 30px;
    height: 30px;
}

.navegue h1 {
    font-size: 1.5em;
    color: #00ADD3;
    padding-bottom: 40px;
}

.navegue {
    padding-right: 150px;
    text-align: center;
}

.navegue ul {
    list-style-type: none;
    padding: 0;
}

.navegue li {
    margin-bottom: 10px;
}

.contato h1 {
    font-size: 1.5em;
    color: #00ADD3;
    padding-bottom: 40px;
}

.contato p {
    margin-bottom: 10px;
}

.contato img {
    margin-right: 10px;
    margin-bottom: 50px;
}

.app h1 {
    padding-bottom: 60px;
    font-size: 1.5em;
    text-align: center;
    color: #00ADD3;
}
.app {
    display: flex;
    flex-direction: column;
}

.imagesapp{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.playlogo{
    width: 200px;
    height: 60px;
}

.applogo{
    width: 200px;
    height: 60px;
}

.app img {
    margin: 0 5px;
}

.licenca{
    display: flex;
    background-color: #1D1E20;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}

.politicas {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.reservados{
    padding-right: 200px;
}

.igln {
    gap: 10px;
    display: flex;
}

@media (max-width: 768px) {
    .eletrix,
    .container2,
    .app, .navegue, .contato {
        flex-basis: 100%;
        gap: 0px;
        text-align: center;
        align-items: center;
    }

    .contato {
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .WhatsApp, .Email {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .WhatsApp img, .Email img {
        margin: 0;
    }

    .igln {
        padding-top: 50px;
    }

    .navegue {
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .politicas {
        display: none;
    }
    
    .logo {
        display: none;
    }

    .navegue {
        padding-right: 0px;
    }
}
