.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    .title {
        margin-bottom: 2rem;
        color: #00ADD3;
        font-weight: 300;
        margin-top: 0.4rem;
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 67%;

        .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            font-size: 1.3rem;
        }

        .image {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.caraio2 {
    margin-bottom: 1rem;
}

.carregador {
    font-size: 2.5rem;
    padding-bottom: 100px;
}

.container2 {
    display: flex;
}

.divcaraio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.caraio {
    a:hover {
        color: #00ADD3;
    }
}
/* For devices with screen width up to 740px */
@media (max-width: 740px) {
    .container {
        .content {
            flex-direction: column;
            width: 100%;
            align-items: center;

            .text {
                margin-bottom: 2rem;
                padding: 0 1rem;
            }
        }

        .text, .image {
            margin: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .carregador {
        text-align: center;
        padding-bottom: 50px;
    }
}
