.container {
    background-color: rgba(0,0,0,.8);
    height: 5rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    position: fixed;
    z-index: 1000;
}

.logo {
    width: 121px;
    height: 67.01px;
    cursor: pointer;
}

.links {
    display: flex;
    gap: 1rem;
    align-items: center;

    a {
        color: white;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        color: #00ADD3;
    }

    .appButton {
        background: none;
        border: none;
        cursor: pointer;
    }
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;

    span {
        height: 0.25rem;
        width: 1.5rem;
        background-color: white;
        margin-bottom: 0.3rem;
        border-radius: 0.1rem;
    }
}

@media (max-width: 740px) {
    .container {
        padding: 0 1rem;
    }

    .logo {
        width: 85px;
        height: 45px;
    }

    .links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 5rem;
        right: 0;
        background-color: rgba(0,0,0,.8);
        width: 100%;
        text-align: center;

        a {
            padding: 1rem 0;
        }
    }

    .links.show {
        display: flex;
        top: 0;
    }

    .hamburger {
        display: flex;
    }
}

.transparent {
    background-color: rgb(0, 0, 0, 0.2);
}

.scrolled {
    background-color: rgba(16, 23, 31, 0.8);
}
